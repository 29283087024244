import React from "react";
import image from "../assets/images/banner/electrise_tes_nuits_au_rythme_underground.png";

const Banner = () => {
  return (
    <section className="banner mt-100">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-12">
            <h2>
              Le bar techno pour des soirées before et after inoubliables !
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h1 className="texte-elec">
              ÉLECTRISE TES NUITS AU RYTHME UNDERGROUND
            </h1>
          </div>
        </div>
        <div className="row justify-content-center gy-3">
          <div className="col-lg-12">
            <a id="btn-a-propos" className="bouton btn-hover" href="#aboutUs">
              À PROPOS
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
