import logo from './logo.svg';

import Header from './components/Header';
import Footer from './components/Footer';
import Banner from './components/Banner';
import Equipe from './components/Equipe';
import Bar_Carte from './components/Bar-Carte';
import Event from './components/Evenement';
import AboutUs from './components/About-Us';



import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './assets/css/style.css';
import reportWebVitals from './reportWebVitals';

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <AboutUs />
      <Event />
      <Equipe />
      <Bar_Carte />
      <Footer />
    </div>
  );
}

export default App;
