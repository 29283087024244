import React from 'react';
import logoHeader from '../assets/images/logo_header.svg';

const Header = () => {
    return (
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className="navbar-brand" href="#">
                                <img className="img-fluid logo" src={logoHeader} alt="Logo" />
                            </a>
                            {/* Bouton de menu burger avec Bootstrap 5 */}
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            {/* Contenu du menu */}
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <div id="pq-menu-contain" className="pq-menu-contain">
                                    <ul id="pq-main-menu" className="navbar-nav ml-auto">
                                        <li className="menu-item"><a className="nav-link" href="#aboutUs">À PROPOS</a></li>
                                        <li className="menu-item"><a className="nav-link" href="#evenement">EVENEMENT</a></li>
                                        <li className="menu-item"><a className="nav-link" href="#equipe">ÉQUIPE</a></li>
                                        <li className="menu-item"><a className="nav-link" href="#bar">BAR</a></li>
                                        <li className="menu-item"><a className="nav-link" href="#contact">CONTACT</a></li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
