import React from "react";
import logoFooter from "../assets/images/logo-footer.png";
import facebook from "../assets/images/reseaux/facebook.svg";
import instagram from "../assets/images/reseaux/instagram.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer id="contact" className=" pt-100">
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-sm-12">
            <hr />
          </div>
          <div className="col-lg-2 col-sm-12">
            <h2 className="tjrplushaut">NOUS SUIVRE</h2>
          </div>
          <div className="col-lg-5 col-sm-12">
            <hr />
          </div>
        </div>
        <div className="row mobile-space pt-50">
          <div className="col-lg-4">
            <div className="row mobile-space justify-content-center align-items-center">
              <div className="col-lg-3 col-sm-1 text-start">
                <img src={logoFooter} />
              </div>
              <div className="col-lg-5 col-sm-5 text-start">
                <a
                  target="_blank"
                  href="https://maps.app.goo.gl/CCtLqpfGDv5inY4n9"
                >
                  22 RUE BENOIT BUNICO 06300 NICE
                </a>
              </div>
              <div className="col-lg-4 col-sm-12 text-start">
                <a href="tel:+33651597689">06 51 59 76 89 </a>
                <a href="mailto:lebarapotes@outlook.fr">
                  LEBARAPOTES@OUTLOOK.FR
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col-2 offset-4">
                <a
                  target="_blank"
                  href=" https://www.instagram.com/lantre_nice/"
                  className="facebook reseau btn-hover"
                >
                  {/* <img src={instagram} alt="" /> */}
                  <FontAwesomeIcon
                    className="reseau-icon"
                    icon={faInstagram}
                    size="2x"
                  />
                </a>
              </div>
              <div className="col-2">
                <a
                  target="_blank"
                  href="https://www.facebook.com/lantrebaravie/"
                  className="facebook reseau btn-hover"
                >
                  {/* <img src={facebook} alt="" /> */}
                  <FontAwesomeIcon
                    className="reseau-icon"
                    icon={faFacebookF}
                    size="2x"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="row">
              <div className="col text-start">
                <p>
                  <span className="engros">Mercredi, jeudi, vend. :&nbsp;</span>
                  21h-02h
                </p>
                <p>
                  <span className="engros">Samedi :&nbsp;</span>21h-02h /
                  05h-09h
                </p>
                <p>
                  <span className="engros">Dimanche :&nbsp;</span>05h-09h
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="row">
              <p className="text-center display-6 fw-bolder">
                Ouverture sur réservation
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
