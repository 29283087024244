import React from "react";
import image1 from "../assets/images/aboutUs/img1.png";
import image2 from "../assets/images/aboutUs/img2.png";
import image3 from "../assets/images/aboutUs/img3.png";

const aboutUs = () => {
    return (
        <section id="aboutUs" className="aboutUs mt-50 pb-100">
            <div className="position-relative aboutUS-laptop">
                <div className="img position-absolute top-0 start-0 ">
                    <img className="img1" src={image1} alt="" />
                    <img className="img2" src={image2} alt="" /> 
                    <img className="img3" src={image3} alt="" />
                </div>
                <div className="aboutus-article position-absolute bottom-0 end-0">
                    <div className="desktop">
                        <h2>QUI SOMMES-NOUS ?</h2>
                    </div>
                    <div>
                        <p>BIENVENUE À L'ANTRE, LE LIEU INCONTOURNABLE POUR LES PASSIONNÉS DE MUSIQUE TECHNO ET PLUS ENCORE.
                        SITUÉ AU CŒUR DE LA VILLE, NOTRE BAR OFFRE UNE EXPÉRIENCE UNIQUE, MÊLANT MUSIQUE UNDERGROUND,
                        COCKTAILS CRÉATIFS ET UNE ATMOSPHÈRE VIBRANTE.</p>
                        <p>QUE CE SOIT POUR UN BEFORE ÉNERGIQUE OU UN AFTER MÉMORABLE, L'ANTRE EST L'ENDROIT IDÉAL POUR PROLONGER
                        TES NUITS ET RENCONTRER D'AUTRES AMATEURS DE MUSIQUE TECHNO.</p>
                    </div>
                </div>
            </div>
            <div className="position-relative aboutUS-mobile">
                <div className="img position-absolute">
                    <img className="img1" src={image1} alt="" />
                    <img className="img2" src={image2} alt="" /> 
                    <img className="img3" src={image3} alt="" />
                </div>
                <div className="aboutus-article position-absolute"> 
                    <div className="mobile">
                        <h2>QUI <span>SOMMES-NOUS ?</span></h2>
                    </div>
                    <div>
                        <p>BIENVENUE À L'ANTRE, LE LIEU INCONTOURNABLE POUR LES PASSIONNÉS DE MUSIQUE TECHNO ET PLUS ENCORE.
                        SITUÉ AU CŒUR DE LA VILLE, NOTRE BAR OFFRE UNE EXPÉRIENCE UNIQUE, MÊLANT MUSIQUE UNDERGROUND,
                        COCKTAILS CRÉATIFS ET UNE ATMOSPHÈRE VIBRANTE.</p>
                        <p>QUE CE SOIT POUR UN BEFORE ÉNERGIQUE OU UN AFTER MÉMORABLE, L'ANTRE EST L'ENDROIT IDÉAL POUR PROLONGER
                        TES NUITS ET RENCONTRER D'AUTRES AMATEURS DE MUSIQUE TECHNO.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default aboutUs;