import React from 'react';
import image from '../assets/images/image-event.png';


const Event = () => {
    return (
        // <section className="event pt-100">
        <section id="evenement" className="event mt-250">
            <div className="position-relative p-5 event-container evenement-laptop">
                <div className="event-article position-absolute start-5 top-100 event-container-gauche">
                    <div>
                        <h2>ÉVÉNEMENTS</h2>
                    </div>
                    <div>
                        <p>REJOINS-NOUS POUR DES SOIRÉES EXCEPTIONNELLES PAR DES DJS DE RENOM ET DES TALENTS ÉMERGENTS DE LA SCÈNE TECHNO UNDERGROUND.</p>
                        <p>CHAQUE SEMAINE, NOUS TE PROPOSONS UNE NOUVELLE PROGRAMMATION AVEC DIFFÉRENTS ARTISTES QUI MIXENT EN LIVE.</p>
                        <p>CONSULTE NOTRE CALENDRIER SUR INSTAGRAM POUR NE MANQUER AUCUN DE NOS ÉVÉNEMENTS À VENIR, QUE CE SOIT POUR UN BEFORE OU UN AFTER.</p>
                    </div>
                </div>
                <div className="position-absolute end-26 top-10 event-container-droit">
                   <img src={image}/>
                
                </div>
            </div>
            <div className="container evenement-mobile">
                <div className="row gap-5 event-container justify-content-center">
                    <div className="col-sm-12">
                        <img src={image}/>
                    </div>
                    <div className="col-sm-10 event-container-gauche">
                        <div>
                            <h2>ÉVÉNEMENT</h2>
                        </div>
                        <div >
                            <p>REJOINS-NOUS POUR DES SOIRÉES EXCEPTIONNELLES PAR DES DJS DE RENOM ET DES TALENTS ÉMERGENTS DE LA SCÈNE TECHNO UNDERGROUND.</p>
                            <p>CHAQUE SEMAINE, NOUS TE PROPOSONS UNE NOUVELLE PROGRAMMATION AVEC DIFFÉRENTS ARTISTES QUI MIXENT EN LIVE.</p>
                            <p>CONSULTE NOTRE CALENDRIER SUR INSTAGRAM POUR NE MANQUER AUCUN DE NOS ÉVÉNEMENTS À VENIR, QUE CE SOIT POUR UN BEFORE OU UN AFTER.</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        
    );
};

export default Event;