import React from "react";
import membre1 from "../assets/images/team/membre.png";
import CommuManager from "../assets/images/team/community_manager.jpeg";
import Dirigeante from "../assets/images/team/dirigeante.jpeg";
import Physio from "../assets/images/team/physionomiste.jpeg";
import ResponsableBar1 from "../assets/images/team/responsable_bar.jpeg";
import ResponsableBar2 from "../assets/images/team/responsable_bar_2.jpeg";
import notre_equipe from "../assets/images/notre_equipe.png";

function Card(img, nom, profession) {
  return (
    <div className="col-lg-3 col-md-6">
      <div className="equipe-item">
        <img
          className="equipe-item-img"
          src={img}
          style={{ height: 250 + "px" }}
        />
        <div className="equipe-item-info">
          <p className="equipe-item-info-nom">{nom}</p>
          <p className="equipe-item-info-profession">{profession}</p>
        </div>
      </div>
    </div>
  );
}
const Equipe = () => {
  return (
    <section id="equipe" className="equipe mt-200">
      <div className="container text-center">
        <div className="row justify-content-center gy-3">
          <div className="col-lg-12">
            <h2>RENCONTRE AVEC</h2>
          </div>
          <div className="col-lg-12 mt-0">
            <h1 className="texte-elec">NOTRE ÉQUIPE</h1>
          </div>
        </div>
        <div className="row justify-content-center gy-3">
          {Card(Dirigeante, "DIANE", "DIRIGEANTE")}
          {Card(membre1, "CHARLOTTE", "ASSISTANTE DE DIRECTION ")}
          {Card(CommuManager, "STELLA", "COMMUNITY MANAGER")}
          {Card(Physio, "STEPHANE", "PHYSIONOMISTE")}
          {Card(ResponsableBar1, "ROMAIN", "RESPONSABLE BAR")}
          {Card(ResponsableBar2, "AURIANNE", "RESPONSABLE BAR")}
        </div>
        <div className="row justify-content-center gy-3">
          <div className="col-lg-12">
            <a
              id="btn-nous-contacter"
              className="bouton btn-hover"
              href="#contact"
            >
              NOUS CONTACTER
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Equipe;
